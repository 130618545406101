<template>
  <div :class="[    'CEESAR-ControlBox',
                    required? 'CEESAR-ControlBox-Required': '', 
                    horizontal? 'CEESAR-ControlBox-Horizontal': '']">
        
        <label class="CEESAR-ControlBoxLabel CEESAR-AlignLabel">
            {{label}}
        </label>

        <slot />
  </div>
</template>

<script>
export default {
    name: "CEESAR-Box",
    props:{
        label: "", 
        horizontal: false,
        required: false
    }
}
</script>

<style>

</style>